import { Poll } from './Poll'
import { Audio } from './Audio'
import { Document } from './Document'
import { Location } from './Location'
import { Sticker } from './Sticker'
import { Unknown } from './Unknown'
import { Video } from './Video'
import { VideoNote } from './VideoNote'
import { Voice } from './Voice'

export const Unsupported = {
	Poll,
	Audio,
	Document,
	Location,
	Sticker,
	Unknown,
	Video,
	VideoNote,
	Voice,
}
