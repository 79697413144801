import { trackEventGrouped } from '../track'
import type { TrackingAction, TrackingGroup } from '../track'

type Referrer = 'editor' | 'overview' | 'settings' | 'feed'

const EVENTS_CATEGORY = 'channel'

const trackChannelEvent = (
	ref: Referrer,
	group: TrackingGroup,
	action: TrackingAction,
	eventName: string,
	params?: UmamiData,
	options?: UmamiOptions,
) => {
	trackEventGrouped(EVENTS_CATEGORY, ref, group, action, eventName, params, options)
}

export const useChannelAnalyticsEvents = (channel: string) => {
	return {
		clicks: {
			newDraft: (ref: 'editor' | 'overview' | 'settings') =>
				trackChannelEvent(ref, { channel }, 'click', 'new_draft'),
			scheduleDraft: (ref: 'editor' | 'feed') => trackChannelEvent(ref, { channel }, 'click', 'schedule'),
		},
	}
}
