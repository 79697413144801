import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import removeMd from 'remove-markdown'
import styled from '@emotion/styled'

import { truncate } from '@prostpost/utils'
import { Paragraph, Text } from '@prostpost/uikit'

const ParagraphHighlighted = styled(Paragraph)`
	i {
		font-style: normal;
		background: ${({ theme }) => theme.colors.yellow_100};
	}
`

type ParsedText =
	| {
			variant: 'titled'
			title: string
			text: string
	  }
	| {
			variant: 'non-titled'
			emphasizedText: string
			normalText: string
	  }

const clearMarkdown = (text: string) =>
	removeMd(text.replace(/(\*|_|~|`|\\|\n)/g, ''), { stripListLeaders: false }).trim()

const useTextPreview = (text: string): ParsedText => {
	// We used to strip MD on FE but now it's moved to BE
	// but we still need that to remove Editor HTML/MD because we use dangerouslySetInnerHTML
	// and tags from Editor will ruin the layout of a preview

	// get first 30 characters of text and check if it contains \n
	const hasTitle = text.slice(0, 30).indexOf('\n') !== -1
	const firstLine = clearMarkdown(hasTitle ? text.split('\n')[0] : text)
	const secondLine = hasTitle ? clearMarkdown(text.slice(firstLine.length + 1)) : ''

	// Emphasize first 3 words
	const emphasized = useMemo((): string[] => {
		const split = firstLine.split(/\s+/)
		return hasTitle ? [firstLine, secondLine] : [split.slice(0, 3).join(' '), ` ${split.slice(3).join(' ')}`]
	}, [firstLine])

	return hasTitle
		? {
				variant: 'titled',
				title: emphasized[0],
				text: emphasized[1],
			}
		: {
				variant: 'non-titled',
				emphasizedText: emphasized[0],
				normalText: emphasized[1],
			}
}

const highlightSubstring = (text: string, search: string) => {
	return search ? (text || '').replace(new RegExp(search, 'i'), '<i>$&</i>') : text
}

export const PreviewText = ({
	ad,
	text,
	tags,
	search,
}: {
	text?: string
	ad: boolean
	tags: string[]
	search: string
}) => {
	const { t } = useTranslation()
	const parsedText = useTextPreview(text || '')

	return (
		<ParagraphHighlighted size={14} color="blue_40" lines={tags.length === 0 ? 2 : 1}>
			{!text && ad ? (
				// as="span" to avoid error that <p> can't be a child of another <p>
				<Paragraph as="span" fontStyle="italic" size={14} color="blue_40">
					{t('draft.preview.feelsEmpty', 'This one feels empty...')}
				</Paragraph>
			) : (
				(() => {
					switch (parsedText.variant) {
						case 'titled':
							return (
								<>
									<Text size={14}>
										<span
											dangerouslySetInnerHTML={{
												__html: highlightSubstring(parsedText.title, search),
											}}
										/>
									</Text>
									<br />
									<span
										dangerouslySetInnerHTML={{
											__html: highlightSubstring(truncate(parsedText.text, 30), search),
										}}
									/>
								</>
							)
						case 'non-titled':
							return (
								<>
									<Text size={14}>
										<span
											dangerouslySetInnerHTML={{
												__html: highlightSubstring(parsedText.emphasizedText, search),
											}}
										/>
									</Text>
									<span
										dangerouslySetInnerHTML={{
											__html: highlightSubstring(truncate(parsedText.normalText, 60), search),
										}}
									/>
								</>
							)
						default:
							return null
					}
				})()
			)}
		</ParagraphHighlighted>
	)
}
