import { jsx } from '@emotion/react/jsx-runtime';
import { useRef, useEffect } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { AnimatePresence, motion } from 'framer-motion';
import { notReachable } from '@prostpost/utils';
import { HideScrollbar } from '@prostpost/hide-scrollbar';

function _arrayLikeToArray(r, a) {
    (null == a || a > r.length) && (a = r.length);
    for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
    return n;
}
function _arrayWithHoles(r) {
    if (Array.isArray(r)) return r;
}
function _arrayWithoutHoles(r) {
    if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _defineProperty(e, r, t) {
    return (
        (r = _toPropertyKey(r)) in e
            ? Object.defineProperty(e, r, {
                  value: t,
                  enumerable: !0,
                  configurable: !0,
                  writable: !0,
              })
            : (e[r] = t),
        e
    );
}
function _iterableToArray(r) {
    if (('undefined' != typeof Symbol && null != r[Symbol.iterator]) || null != r['@@iterator']) return Array.from(r);
}
function _iterableToArrayLimit(r, l) {
    var t = null == r ? null : ('undefined' != typeof Symbol && r[Symbol.iterator]) || r['@@iterator'];
    if (null != t) {
        var e,
            n,
            i,
            u,
            a = [],
            f = !0,
            o = !1;
        try {
            if (((i = (t = t.call(r)).next), 0 === l));
            else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
        } catch (r) {
            (o = !0), (n = r);
        } finally {
            try {
                if (!f && null != t.return && ((u = t.return()), Object(u) !== u)) return;
            } finally {
                if (o) throw n;
            }
        }
        return a;
    }
}
function _nonIterableRest() {
    throw new TypeError(
        'Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.',
    );
}
function _nonIterableSpread() {
    throw new TypeError(
        'Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.',
    );
}
function ownKeys(e, r) {
    var t = Object.keys(e);
    if (Object.getOwnPropertySymbols) {
        var o = Object.getOwnPropertySymbols(e);
        r &&
            (o = o.filter(function (r) {
                return Object.getOwnPropertyDescriptor(e, r).enumerable;
            })),
            t.push.apply(t, o);
    }
    return t;
}
function _objectSpread2(e) {
    for (var r = 1; r < arguments.length; r++) {
        var t = null != arguments[r] ? arguments[r] : {};
        r % 2
            ? ownKeys(Object(t), !0).forEach(function (r) {
                  _defineProperty(e, r, t[r]);
              })
            : Object.getOwnPropertyDescriptors
              ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t))
              : ownKeys(Object(t)).forEach(function (r) {
                    Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
                });
    }
    return e;
}
function _objectWithoutProperties(e, t) {
    if (null == e) return {};
    var o,
        r,
        i = _objectWithoutPropertiesLoose(e, t);
    if (Object.getOwnPropertySymbols) {
        var s = Object.getOwnPropertySymbols(e);
        for (r = 0; r < s.length; r++)
            (o = s[r]), t.includes(o) || ({}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]));
    }
    return i;
}
function _objectWithoutPropertiesLoose(r, e) {
    if (null == r) return {};
    var t = {};
    for (var n in r)
        if ({}.hasOwnProperty.call(r, n)) {
            if (e.includes(n)) continue;
            t[n] = r[n];
        }
    return t;
}
function _slicedToArray(r, e) {
    return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _toConsumableArray(r) {
    return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _toPrimitive(t, r) {
    if ('object' != typeof t || !t) return t;
    var e = t[Symbol.toPrimitive];
    if (void 0 !== e) {
        var i = e.call(t, r || 'default');
        if ('object' != typeof i) return i;
        throw new TypeError('@@toPrimitive must return a primitive value.');
    }
    return ('string' === r ? String : Number)(t);
}
function _toPropertyKey(t) {
    var i = _toPrimitive(t, 'string');
    return 'symbol' == typeof i ? i : i + '';
}
function _unsupportedIterableToArray(r, a) {
    if (r) {
        if ('string' == typeof r) return _arrayLikeToArray(r, a);
        var t = {}.toString.call(r).slice(8, -1);
        return (
            'Object' === t && r.constructor && (t = r.constructor.name),
            'Map' === t || 'Set' === t
                ? Array.from(r)
                : 'Arguments' === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t)
                  ? _arrayLikeToArray(r, a)
                  : void 0
        );
    }
}

var animationVariants = function animationVariants(translateY) {
    return {
        hidden: {
            opacity: 0,
            x: 20,
            y: translateY,
            transition: {
                type: 'spring',
                damping: 12,
                stiffness: 100,
            },
        },
        visible: {
            opacity: 1,
            x: 0,
            y: translateY,
            transition: {
                type: 'spring',
                damping: 12,
                stiffness: 100,
            },
        },
    };
};
var animation = function animation(translateY, isAnimated) {
    return {
        animate: 'visible',
        initial: 'visible',
        exit: 'visible',
        variants: animationVariants(translateY),
    };
};

var _excluded$1 = [
    'gap',
    'itemHeight',
    'infiniteQueryResult',
    'listContainerStyles',
    'renderOnStatus',
    'renderItem',
    'onStatus',
];
var InfiniteScroll$1 = function InfiniteScroll(_ref) {
    var _props$items;
    var gap = _ref.gap,
        itemHeight = _ref.itemHeight,
        infiniteQueryResult = _ref.infiniteQueryResult,
        listContainerStyles = _ref.listContainerStyles,
        renderOnStatus = _ref.renderOnStatus,
        renderItem = _ref.renderItem,
        onStatus = _ref.onStatus,
        props = _objectWithoutProperties(_ref, _excluded$1);
    var parentRef = useRef(null);
    var data = infiniteQueryResult.data,
        status = infiniteQueryResult.status,
        hasNextPage = infiniteQueryResult.hasNextPage,
        isFetchingNextPage = infiniteQueryResult.isFetchingNextPage,
        fetchNextPage = infiniteQueryResult.fetchNextPage,
        error = infiniteQueryResult.error;
    var nextPageFailed =
        status === 'error' && ((data === null || data === void 0 ? void 0 : data.pages.length) || 0) > 0;
    useEffect(
        function () {
            var rows = data ? data.pages[data.pages.length - 1].data : [];
            props.addNextPageItems(rows);
        },
        [data === null || data === void 0 ? void 0 : data.pages.length],
    );
    var rowVirtualizer = useVirtualizer({
        count: props.items.length + 1,
        getScrollElement: function getScrollElement() {
            return parentRef.current;
        },
        estimateSize: function estimateSize() {
            return itemHeight;
        },
        overscan: 5,
    });
    useEffect(
        function () {
            switch (status) {
                case 'idle':
                case 'loading':
                    return onStatus({
                        status: status,
                    });
                case 'success':
                    return onStatus({
                        status: status,
                        items: props.items,
                        count: props.items.length,
                    });
                case 'error':
                    if (data !== null && data !== void 0 && data.pages.length) return;
                    return onStatus({
                        status: status,
                        error: error,
                    });
                default:
                    notReachable(status);
            }
        },
        [status],
    );
    useEffect(
        function () {
            var _reverse = _toConsumableArray(rowVirtualizer.getVirtualItems()).reverse(),
                _reverse2 = _slicedToArray(_reverse, 1),
                lastItem = _reverse2[0];
            if (!lastItem) {
                return;
            }
            if (lastItem.index >= props.items.length - 1 && hasNextPage && !isFetchingNextPage && !error) {
                fetchNextPage()
                    .then(function (response) {
                        if (response.status === 'error') {
                            onStatus({
                                status: 'next_page_failed',
                            });
                        } else {
                            onStatus({
                                status: 'next_page_loaded',
                            });
                        }
                    })
                    .catch(function () {
                        onStatus({
                            status: 'next_page_failed',
                        });
                    });
            }
        },
        [hasNextPage, fetchNextPage, props.items.length, isFetchingNextPage, rowVirtualizer.getVirtualItems()],
    );
    switch (status) {
        case 'idle':
        case 'loading':
            return renderOnStatus({
                status: status,
            });
        case 'success':
        case 'error':
            if (status === 'error' && !nextPageFailed) {
                return renderOnStatus({
                    status: status,
                    error: error,
                });
            }
            return jsx('div', {
                style: {
                    position: 'relative',
                    height: '100%',
                    width: '100%',
                },
                children: jsx(HideScrollbar, {
                    ref: parentRef,
                    style: {
                        height: '100%',
                        width: '100%',
                        overflow: 'auto',
                        position: 'relative',
                    },
                    children: jsx('ul', {
                        style: _objectSpread2(
                            {
                                width: '100%',
                                position: 'relative',
                                height: ''.concat(rowVirtualizer.getTotalSize(), 'px'),
                            },
                            listContainerStyles,
                        ),
                        children: jsx(AnimatePresence, {
                            mode: 'popLayout',
                            initial: false,
                            children:
                                ((_props$items = props.items) === null || _props$items === void 0
                                    ? void 0
                                    : _props$items.length) > 0
                                    ? rowVirtualizer.getVirtualItems().map(function (virtualRow) {
                                          var item = props.items[virtualRow.index];
                                          var isLoaderRow = virtualRow.index > props.items.length - 1;
                                          return jsx(
                                              motion.li,
                                              _objectSpread2(
                                                  _objectSpread2({}, animation(virtualRow.start)),
                                                  {},
                                                  {
                                                      style: _objectSpread2(
                                                          {
                                                              position: 'absolute',
                                                              top: 0,
                                                              left: 0,
                                                              width: '100%',
                                                              listStyle: 'none',
                                                          },
                                                          gap
                                                              ? {
                                                                    paddingTop: ''.concat(gap, 'px'),
                                                                    paddingBottom: ''.concat(gap, 'px'),
                                                                }
                                                              : {},
                                                      ),
                                                      children:
                                                          isLoaderRow && !nextPageFailed && hasNextPage
                                                              ? renderOnStatus({
                                                                    status: 'loading_more',
                                                                })
                                                              : item
                                                                ? renderItem({
                                                                      index: virtualRow.index,
                                                                      itemUuid: item,
                                                                  })
                                                                : nextPageFailed
                                                                  ? renderOnStatus({
                                                                        status: 'next_page_failed',
                                                                    })
                                                                  : renderOnStatus({
                                                                        status: 'no_more_items',
                                                                        count: props.items.length,
                                                                    }),
                                                  },
                                              ),
                                              virtualRow.key,
                                          );
                                      })
                                    : renderOnStatus({
                                          status: 'no_items',
                                      }),
                        }),
                    }),
                }),
            });
        default:
            return notReachable(status);
    }
};

var _excluded = [
    'gap',
    'itemHeight',
    'infiniteQueryResult',
    'listContainerStyles',
    'renderOnStatus',
    'renderItem',
    'onStatus',
];
var InfiniteScrollDynamic = function InfiniteScrollDynamic(_ref) {
    var _items$0$start, _items$;
    var gap = _ref.gap,
        itemHeight = _ref.itemHeight,
        infiniteQueryResult = _ref.infiniteQueryResult,
        listContainerStyles = _ref.listContainerStyles,
        renderOnStatus = _ref.renderOnStatus,
        renderItem = _ref.renderItem,
        onStatus = _ref.onStatus,
        props = _objectWithoutProperties(_ref, _excluded);
    var parentRef = useRef(null);
    var data = infiniteQueryResult.data,
        status = infiniteQueryResult.status,
        hasNextPage = infiniteQueryResult.hasNextPage,
        isFetchingNextPage = infiniteQueryResult.isFetchingNextPage,
        fetchNextPage = infiniteQueryResult.fetchNextPage,
        error = infiniteQueryResult.error;
    var nextPageFailed =
        status === 'error' && ((data === null || data === void 0 ? void 0 : data.pages.length) || 0) > 0;
    useEffect(
        function () {
            var rows = data ? data.pages[data.pages.length - 1].data : [];
            props.addNextPageItems(rows);
        },
        [data === null || data === void 0 ? void 0 : data.pages.length],
    );
    var rowVirtualizer = useVirtualizer({
        count: props.items.length + 1,
        getScrollElement: function getScrollElement() {
            return parentRef.current;
        },
        estimateSize: function estimateSize() {
            return itemHeight;
        },
        overscan: 5,
    });
    useEffect(
        function () {
            switch (status) {
                case 'idle':
                case 'loading':
                    return onStatus({
                        status: status,
                    });
                case 'success':
                    return onStatus({
                        status: status,
                        items: props.items,
                        count: props.items.length,
                    });
                case 'error':
                    if (data !== null && data !== void 0 && data.pages.length) return;
                    return onStatus({
                        status: status,
                        error: error,
                    });
                default:
                    notReachable(status);
            }
        },
        [status],
    );
    useEffect(
        function () {
            var _reverse = _toConsumableArray(rowVirtualizer.getVirtualItems()).reverse(),
                _reverse2 = _slicedToArray(_reverse, 1),
                lastItem = _reverse2[0];
            if (!lastItem) {
                return;
            }
            if (lastItem.index >= props.items.length - 1 && hasNextPage && !isFetchingNextPage && !error) {
                fetchNextPage()
                    .then(function (response) {
                        if (response.status === 'error') {
                            onStatus({
                                status: 'next_page_failed',
                            });
                        } else {
                            onStatus({
                                status: 'next_page_loaded',
                            });
                        }
                    })
                    .catch(function () {
                        onStatus({
                            status: 'next_page_failed',
                        });
                    });
            }
        },
        [hasNextPage, fetchNextPage, props.items.length, isFetchingNextPage, rowVirtualizer.getVirtualItems()],
    );
    var items = rowVirtualizer.getVirtualItems();
    switch (status) {
        case 'idle':
        case 'loading':
            return renderOnStatus({
                status: status,
            });
        case 'success':
        case 'error':
            if (status === 'error' && !nextPageFailed) {
                return renderOnStatus({
                    status: status,
                    error: error,
                });
            }
            return jsx('div', {
                style: {
                    position: 'relative',
                    height: '100%',
                    width: '100%',
                },
                children: jsx(HideScrollbar, {
                    ref: parentRef,
                    style: {
                        height: '100%',
                        width: '100%',
                        overflowY: 'auto',
                        position: 'relative',
                        contain: 'strict',
                    },
                    children: jsx('div', {
                        style: _objectSpread2(
                            {
                                width: '100%',
                                position: 'relative',
                                height: rowVirtualizer.getTotalSize(),
                            },
                            listContainerStyles,
                        ),
                        children: jsx('ul', {
                            style: {
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                transform: 'translateY('.concat(
                                    (_items$0$start =
                                        (_items$ = items[0]) === null || _items$ === void 0
                                            ? void 0
                                            : _items$.start) !== null && _items$0$start !== void 0
                                        ? _items$0$start
                                        : 0,
                                    'px)',
                                ),
                            },
                            children:
                                props.items && props.items.length > 0
                                    ? items.map(function (virtualRow) {
                                          var item = props.items[virtualRow.index];
                                          var isLoaderRow = virtualRow.index > props.items.length - 1;
                                          return jsx(
                                              'li',
                                              {
                                                  'data-index': virtualRow.index,
                                                  ref: rowVirtualizer.measureElement,
                                                  style: _objectSpread2(
                                                      {
                                                          listStyle: 'none',
                                                      },
                                                      gap
                                                          ? {
                                                                paddingTop: ''.concat(gap, 'px'),
                                                                paddingBottom: ''.concat(gap, 'px'),
                                                            }
                                                          : {},
                                                  ),
                                                  children:
                                                      isLoaderRow && !nextPageFailed && hasNextPage
                                                          ? renderOnStatus({
                                                                status: 'loading_more',
                                                            })
                                                          : item
                                                            ? renderItem({
                                                                  index: virtualRow.index,
                                                                  itemUuid: item,
                                                              })
                                                            : nextPageFailed
                                                              ? renderOnStatus({
                                                                    status: 'next_page_failed',
                                                                })
                                                              : renderOnStatus({
                                                                    status: 'no_more_items',
                                                                    count: props.items.length,
                                                                }),
                                              },
                                              virtualRow.key,
                                          );
                                      })
                                    : renderOnStatus({
                                          status: 'no_items',
                                      }),
                        }),
                    }),
                }),
            });
        default:
            return notReachable(status);
    }
};

var InfiniteScroll = Object.assign(InfiniteScroll$1, {
    Dynamic: InfiniteScrollDynamic,
});

export { InfiniteScroll };
