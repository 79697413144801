import type { PricingPlan } from 'app/domains/PricingPlan'

export type NotificationsToBeResolved = 'VERIFY_EMAIL'
export type NotificationsOptional = 'WELCOME_MESSAGE' | 'WELCOME_ONBOARDING_NOT_PASSED'
export type Notifications = NotificationsOptional | NotificationsToBeResolved
export type NotificationStatus = 'VISIBLE' | 'HIDDEN'

export const NotificationsEnum: { [key in Notifications]: Notifications } = {
	VERIFY_EMAIL: 'VERIFY_EMAIL',
	WELCOME_MESSAGE: 'WELCOME_MESSAGE',
	WELCOME_ONBOARDING_NOT_PASSED: 'WELCOME_ONBOARDING_NOT_PASSED',
}

export type UserLimits = {
	posts: number
	members: number
	channels: number
	monthlyPublishedPosts: number
}

export type UserFeedPreferences = {
	isPostImagesShown: boolean
	isNotifyOnPublishEnabled: boolean
	isUnsupportedPostsShown: boolean
	isBotNotificationSilent: boolean
}

export type UserMorePreferences = {
	disableTracking: boolean
	sidebarIsOpen: boolean
	feedIsOpen: boolean
}

export type UserPreferences = {
	plan: PricingPlan
	nextSubscriptionPeriod: string

	timeFormat24: boolean
	notifications: { [key in Notifications]?: NotificationStatus }

	card?: string
	lang?: string
	phone?: string
	country?: string
	timeZone?: string

	limits: UserLimits
	feed: UserFeedPreferences
	more: UserMorePreferences
}
