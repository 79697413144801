import { useMemo } from 'react'
import { runInAction } from 'mobx'
import { useTheme } from '@emotion/react'
import { observer } from 'mobx-react-lite'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckCheck } from 'lucide-react'

import { InfiniteScroll } from '@prostpost/infinite-scroll'
import { Box, Button, Skeleton, Text, Flex } from '@prostpost/uikit'
import { constant, notReachable } from '@prostpost/utils'

import { useFeed } from 'app/domains/Post/api'
import { usePostsStore } from 'app/domains/Post/store'
import { unsupportedPostTypes } from 'app/domains/Post'
import { useChannelsStore } from 'app/domains/Channel/store'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import type { FeedPost } from 'app/domains/Post'
import type { ChannelActive } from 'app/domains/Channel'

import { ErrorWidget, NoPostsPlaceholder } from '../../components'

const LIST_END_PADDING = '220px'

type Msg = { type: 'on_open_editor'; channelName: string }

type Props = {
	isEditorOpen: boolean
	channel: ChannelActive
	renderItem: (item: FeedPost) => React.ReactElement | null
	onMsg: (msg: Msg) => void
}

export const DataLoader = observer(function DataLoader({ isEditorOpen, channel, renderItem, onMsg }: Props) {
	const theme = useTheme()
	const { t } = useTranslation()

	const postsStore = usePostsStore()
	const channels = useChannelsStore()
	const { preferences } = useOnboardedUserStore()

	// do not use channel.history directly in props below since it raises mobx warning
	const isChannelHistoryLoaded = channel.history
	const [lastLoadedPage, setLastLoadedPage] = useState(0)

	const { feedQuery } = useFeed(
		channel.name,
		{
			range: undefined,
			pageParam: undefined,
			perPage: postsStore.POSTS_PER_PAGE,
			showOnlySupportedPostTypes: !preferences.feed.isUnsupportedPostsShown,
		},
		{ enabled: channels.activeList.length > 0 },
	)

	const posts = useMemo(() => {
		if (preferences.feed.isUnsupportedPostsShown) return postsStore.posts
		return postsStore.posts.filter(post => {
			switch (post.type) {
				case 'HISTORY':
					if (!post.meta) return true
					// @ts-expect-error
					return !unsupportedPostTypes.includes(post.meta.type)
				case 'INTERNAL':
				case 'INTERNAL_PUBLISHING':
				default:
					return true
			}
		})
	}, [preferences.feed.isUnsupportedPostsShown, postsStore.posts.length])

	// Do not proceed if user has no active channels
	if (channels.activeList.length === 0) {
		return (
			<NoPostsPlaceholder
				type="SINGLE_CHANNEL"
				isEditorOpen={!!isEditorOpen}
				historyStatus={isChannelHistoryLoaded}
				onMsg={onMsg}
			/>
		)
	}

	useEffect(() => {
		switch (feedQuery.status) {
			case 'idle':
			case 'loading':
			case 'error':
				break
			case 'success':
				runInAction(() => {
					const feedHistoryImagesNotLoaded = feedQuery.data?.pages.some(page =>
						page.data.some(post => {
							switch (post.type) {
								case 'HISTORY':
									return post.imagesToLoad > 0 && !post.images?.length
								case 'INTERNAL':
								case 'INTERNAL_PUBLISHING':
								default:
									return false
							}
						}),
					)

					// set flag in preferences so we can show a banner in apps/poster/src/routes/Wrappers/WithAside/layout/Banners/Banners.tsx
					preferences.feedHistoryImagesNotLoaded = feedHistoryImagesNotLoaded

					if (feedHistoryImagesNotLoaded && !localStorage.getItem('imagesProcessingWarning')) {
						localStorage.setItem('imagesProcessingWarning', 'visible')
					} else if (!feedHistoryImagesNotLoaded) {
						localStorage.removeItem('imagesProcessingWarning')
					}
				})
				break
			default:
				notReachable(feedQuery)
		}
	}, [feedQuery.status])

	return (
		<InfiniteScroll.Dynamic
			gap={8}
			itemHeight={136}
			infiniteQueryResult={feedQuery}
			items={posts.map(({ uuid }) => uuid)}
			addNextPageItems={nextPage => {
				runInAction(() => {
					if (feedQuery.data?.pages.length === lastLoadedPage + 1) {
						postsStore.setPosts([...posts, ...nextPage])
						setLastLoadedPage(lastLoadedPage + 1)
					}
				})
			}}
			onStatus={data => {
				runInAction(() => {
					switch (data.status) {
						case 'idle':
						case 'loading':
						case 'error':
							// return postsStore.setPosts([])
							break
						case 'success':
							postsStore.setPosts(feedQuery.data?.pages[0].data || [])
							break
						case 'next_page_failed':
						case 'next_page_loaded':
							break
						default:
							notReachable(data)
					}
				})
			}}
			renderItem={({ itemUuid }) => {
				return runInAction(() => {
					const item = posts.find(i => i.uuid === itemUuid)
					if (!item) return null
					return renderItem(item)
				})
			}}
			renderOnStatus={data => {
				switch (data.status) {
					case 'idle':
					case 'loading':
						return (
							<Box w="100%" mt={2}>
								<Skeleton.List variant="loading" />
							</Box>
						)

					case 'error':
						switch (data.error.code) {
							case 'UNKNOWN_ERROR':
							case 'NOT_VALID_ERROR':
								return <ErrorWidget pt={8} error={data.error} onClickTryAgain={feedQuery.refetch} />
							case 'UNABLE_TO_GET_FEED':
								return (
									<ErrorWidget
										pt={8}
										type="KNOWN_EXPECTED"
										error={data.error}
										onClickTryAgain={feedQuery.refetch}
									/>
								)
							default:
								return notReachable(data.error.code)
						}

					case 'loading_more':
						return posts.length < 13 ? (
							<Box w="100%" mt={2}>
								<Skeleton isAnimated height={80} width="100%" borderRadius="mid" />
							</Box>
						) : null

					case 'no_more_items':
						return data.count >= postsStore.POSTS_PER_PAGE ? (
							<Flex align="center" just="center" w="100%" h="64px">
								<CheckCheck size={16} color={theme.colors.blue_40} />
								<Text size={13} ml={1} color="blue_40">
									{isEditorOpen
										? t('content:feed.list.noMorePosts', 'Channel has no more posts')
										: t(
												'content:feed.list.noMoreScheduledDrafts',
												'You have no more scheduled posts',
											)}
								</Text>
							</Flex>
						) : null

					case 'next_page_failed':
						return posts.length >= postsStore.POSTS_PER_PAGE ? (
							<Box w="100%" position="absolute" bottom={4}>
								<Button.Action
									fullWidth
									size="mid"
									variant="primary"
									mt={LIST_END_PADDING}
									onClick={() => {
										feedQuery
											.fetchNextPage()
											.then(constant(null))
											.catch(e => {
												throw e
											})
									}}
								>
									{t('content:feed.list.refetchPage', 'Failed. Try again')}
								</Button.Action>
							</Box>
						) : null

					case 'no_items':
						return (
							<NoPostsPlaceholder
								type="SINGLE_CHANNEL"
								isEditorOpen={!!isEditorOpen}
								historyStatus={isChannelHistoryLoaded}
								onMsg={onMsg}
							/>
						)

					default:
						return notReachable(data)
				}
			}}
		/>
	)
})
