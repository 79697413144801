import lottie from 'lottie-web'
import { useCallback } from 'react'
import { useQuery } from 'react-query'
import { useTheme } from '@emotion/react'
import * as Icons from 'lucide-react'
import type { AxiosError } from 'axios'

import { Box, Flex, Image } from '@prostpost/uikit'

import { config } from 'app/config'

type Props = {
	size?: number
	emojiId: string
	loopAnimation?: boolean
	tag?: 'span' | 'div'
}

const Placeholder = ({ size, margin = 2 }: { size: number; margin?: number }) => {
	const theme = useTheme()
	return (
		<Box
			as="span"
			mx={`${margin}px`}
			bg="blue_20_opaque"
			borderRadius="tiny"
			w={`${size}px`}
			h={`${size}px`}
			style={{ display: 'inline-block', verticalAlign: 'middle' }}
		>
			<Flex as="span" align="center" just="center" h="100%">
				<Icons.Smile size={Math.round(size / 1.7)} strokeWidth={2} color={theme.colors.blue_40} />
			</Flex>
		</Box>
	)
}

export const TelegramAnimatedEmoji = ({ emojiId, tag = 'span', size = 16, loopAnimation = true }: Props) => {
	const {
		data: emojiData,
		status,
		error,
	} = useQuery<unknown, AxiosError>({
		enabled: !!emojiId,
		queryKey: ['emoji', emojiId],
		queryFn: () =>
			config.network.proxy
				.get(
					`${config.constants.MEDIA_TELEGRAM_EMOJI_URL}/${emojiId}.json`,
					{ baseURL: '' }, // override "/api" base URL
				)
				.then(({ data }) => data),
	})

	// since we need to render span container and lottie-react is not configurable
	// and renders only div we should use lottie-web directly to render svg inside span tag
	const spanRef = useCallback(
		(node: HTMLDivElement | null) => {
			if (node !== null) {
				lottie.loadAnimation({
					renderer: 'svg',
					autoplay: true,
					loop: loopAnimation,
					animationData: emojiData,
					container: node,
				})
			}
		},
		[emojiData],
	)

	switch (status) {
		case 'idle':
		case 'loading':
			return <Placeholder size={size} />
		case 'error':
			// if animated .json file is not found try to load static .webp file
			return error.status === 404 ? (
				<span
					style={{
						width: size,
						height: size,
						margin: '0 2px',
						display: 'inline-block',
						verticalAlign: 'middle',
					}}
				>
					<Image
						alt=""
						width={`${size}px`}
						height={`${size}px`}
						loaderSize={[`${size}px`, `${size}px`]}
						loader={() => <Placeholder margin={0} size={size} />}
						loaderContainerTag="span"
						src={`${config.constants.MEDIA_TELEGRAM_EMOJI_URL}/${emojiId}.webp`}
					/>
				</span>
			) : (
				<Placeholder size={size} />
			)
		case 'success':
			return emojiData ? (
				<Box
					mx="2px"
					as={tag}
					w={`${size}px`}
					h={`${size}px`}
					style={{ display: 'inline-block', verticalAlign: 'middle' }}
					ref={spanRef}
				/>
			) : (
				<Placeholder size={size} />
			)
		default:
			return <Placeholder size={size} />
	}
}
