import { Global, css, useTheme } from '@emotion/react'
import type { MotionProps } from 'framer-motion'

export const animation: MotionProps = {
	layout: true,
	animate: 'open',
	exit: 'collapsed',
	initial: 'collapsed',
	transition: { ease: 'easeInOut', duration: 0.3 },
	style: {
		width: '100%',
		height: 'auto',
		position: 'relative',
	},
	variants: {
		// we need animate height for smooth movement of tiles below
		// so they smoothly come on top
		open: { y: 0, opacity: 1, height: 'auto' },
		collapsed: { y: 10, opacity: 0, height: 0 },
	},
}

export const EditorGlobalStyles = () => {
	const theme = useTheme()
	return (
		<Global
			styles={css`
				.ProseMirror {
					min-height: 300px;
					padding-bottom: 60px;
				}

				.ProseMirror p.is-editor-empty:first-of-type::before {
					content: attr(data-placeholder);
					float: left;
					height: 0;
					pointer-events: none;
					color: #adb5bd;
				}

				.editor-text-bold {
					font-weight: 600;
				}

				.editor-text-spoiler {
					position: relative;

					/* hack to put text in front of pseudo element */

					/* https://stackoverflow.com/questions/3032856/is-it-possible-to-set-the-stacking-order-of-pseudo-elements-below-their-parent-e#comment127633268_47342657 */
					transform: translateZ(-1px);

					&::after {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						opacity: 0.3;
						background: repeating-linear-gradient(45deg, #f7f9ff, #f7f9ff 5px, #8298bd 5px, #8298bd 10px);
						border-radius: 4px;
					}
				}

				.editor-paragraph {
					font-weight: 400;
					line-height: 24px;
				}

				.editor-text-code {
					padding: 0 4px;
					color: ${theme.colors.black_50};
					background: ${theme.colors.blue_10};
					border-radius: 4px;
				}

				.editor-text-link {
					color: ${theme.colors.blue_100};
				}

				.editor-text-tag {
					font-weight: 500;
					color: #003ad8;
				}
			`}
		/>
	)
}
