import type { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import type { JsonApiErrorResponse, ParsedError, JsonApiResponseSingle } from '@prostpost/jsonapi'
import { parseFirstError, parseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import { updateSessionStorage } from '../utils'
import type { UserPreferences } from '..'

import { PreferencesSchema } from './schemas'

export type AdditionalPreferences = {
	simpleFeedView?: boolean
	supportedOnlyFeedView?: boolean
	notifyOnPublish?: boolean
	silentBotNotifications?: boolean
	sidebarIsOpen?: boolean
	feedIsOpen?: boolean
}

export type UpdatePreferencesData = {
	country?: string
	lang?: string
	timeZone?: string
	timeFormat24?: boolean
	more?: AdditionalPreferences
}

const knownErrors = ['UNABLE_TO_UPDATE_PREFERENCES'] as const

const updateUserPreferences = (data: UpdatePreferencesData): Promise<UserPreferences> =>
	axiosInstance
		.patch<JsonApiResponseSingle>('/auth/preferences', data)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data, ['data.notifications'])
			const preferences = PreferencesSchema.parse(result.data)
			updateSessionStorage(preferences.timeZone, preferences.timeFormat24)
			return preferences
		})

export const useUpdateUserPreferences = () => {
	return {
		updateUserPreferencesMutation: useMutation<
			UserPreferences,
			ParsedError<(typeof knownErrors)[number]>,
			UpdatePreferencesData
		>(updateUserPreferences),
	}
}
