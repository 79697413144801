import { z } from 'zod'

import { UserSchema } from 'app/domains/User/api/schemas'

import type { ChannelActive, ChannelLocked, ChannelArchived, ChannelNotArchived, ChannelRecentTags } from '..'

export const ActiveChannelSchema: z.ZodSchema<ChannelActive, z.ZodTypeDef, unknown> = z
	.object({
		locked: z.optional(z.literal(false)),
		archivedAt: z.literal(undefined),
		tgId: z.number(),
		uuid: z.string().uuid(),
		name: z.string(),
		members: z.number(),
		userUuid: z.string().uuid(),
		admins: z.optional(z.array(UserSchema)),
		historyLoaded: z.optional(z.string()),
		avaSmall: z.optional(z.string()),
		avaBig: z.optional(z.string()),
		title: z.optional(z.string()),
		desc: z.optional(z.string()),
		createdAt: z.string(),
		isPrivate: z.boolean(),
		firstParsedPostPublishedAt: z.optional(z.string()),
	})
	.transform(({ firstParsedPostPublishedAt, isPrivate, ...response }) => {
		if (response.historyLoaded) {
			return {
				...response,
				type: 'ACTIVE',
				locked: false,
				admins: [],
				description: response.desc,
				alias: response.title ?? `@${response.name}`,
				history: 'LOADED',
				firstParsedPostPublishedAt,
				access: isPrivate ? 'PRIVATE' : 'PUBLIC',
			}
		}

		return {
			...response,
			type: 'ACTIVE',
			locked: false,
			admins: [],
			description: response.desc,
			alias: response.title ?? `@${response.name}`,
			history: 'NOT_LOADED',
			access: isPrivate ? 'PRIVATE' : 'PUBLIC',
		}
	})

export const LockedChannelSchema: z.ZodSchema<ChannelLocked, z.ZodTypeDef, unknown> = z
	.object({
		locked: z.literal(true),
		archivedAt: z.literal(undefined),
		tgId: z.number(),
		uuid: z.string().uuid(),
		name: z.string(),
		members: z.number(),
		userUuid: z.string().uuid(),
		admins: z.optional(z.array(UserSchema)),
		historyLoaded: z.optional(z.string()),
		avaSmall: z.optional(z.string()),
		avaBig: z.optional(z.string()),
		title: z.optional(z.string()),
		desc: z.optional(z.string()),
		createdAt: z.string(),
		isPrivate: z.boolean(),
		firstParsedPostPublishedAt: z.optional(z.string()),
	})
	.transform(({ firstParsedPostPublishedAt, isPrivate, ...response }) => {
		if (response.historyLoaded) {
			return {
				...response,
				type: 'LOCKED',
				admins: [],
				description: response.desc,
				alias: response.title ?? `@${response.name}`,
				history: 'LOADED',
				firstParsedPostPublishedAt,
				access: isPrivate ? 'PRIVATE' : 'PUBLIC',
			}
		}

		return {
			...response,
			type: 'LOCKED',
			admins: [],
			description: response.desc,
			alias: response.title ?? `@${response.name}`,
			history: 'NOT_LOADED',
			access: isPrivate ? 'PRIVATE' : 'PUBLIC',
		}
	})

export const ArchivedChannelSchema: z.ZodSchema<ChannelArchived, z.ZodTypeDef, unknown> = z
	.object({
		locked: z.boolean(),
		archivedAt: z.string(),
		tgId: z.number(),
		uuid: z.string().uuid(),
		name: z.string(),
		members: z.number(),
		userUuid: z.string().uuid(),
		admins: z.optional(z.array(UserSchema)),
		historyLoaded: z.optional(z.string()),
		avaSmall: z.optional(z.string()),
		avaBig: z.optional(z.string()),
		title: z.optional(z.string()),
		desc: z.optional(z.string()),
		createdAt: z.string(),
		isPrivate: z.boolean(),
		firstParsedPostPublishedAt: z.optional(z.string()),
	})
	.transform(({ firstParsedPostPublishedAt, isPrivate, ...response }) => {
		if (response.historyLoaded) {
			return {
				...response,
				type: 'ARCHIVED',
				admins: [],
				description: response.desc,
				alias: response.title ?? `@${response.name}`,
				history: 'LOADED',
				firstParsedPostPublishedAt,
				access: isPrivate ? 'PRIVATE' : 'PUBLIC',
			}
		}

		return {
			...response,
			type: 'ARCHIVED',
			admins: [],
			description: response.desc,
			alias: response.title ?? `@${response.name}`,
			history: 'NOT_LOADED',
			access: isPrivate ? 'PRIVATE' : 'PUBLIC',
		}
	})

export const NotArchivedChannelSchema: z.ZodSchema<ChannelNotArchived, z.ZodTypeDef, unknown> = z.union([
	ActiveChannelSchema,
	LockedChannelSchema,
])

export const ChannelRecentTagsSchema: z.ZodSchema<ChannelRecentTags, z.ZodTypeDef, unknown> = z
	.object({
		tags: z.array(
			z.object({
				tag: z.string(),
				count: z.number(),
			}),
		),
	})
	.transform(({ tags }) => tags)
