import { z } from 'zod'

import { PricingPlanSchema } from 'app/domains/PricingPlan/api/schemas'

import type { UserPreferences, NotificationStatus, Notifications } from '..'
import { NotificationsEnum } from '..'

const NotificationsSchema: z.ZodSchema<{ [key in Notifications]?: NotificationStatus }, z.ZodTypeDef, unknown> = z
	.record(
		z.union([
			z.literal(NotificationsEnum.VERIFY_EMAIL),
			z.literal(NotificationsEnum.WELCOME_MESSAGE),
			z.literal(NotificationsEnum.WELCOME_ONBOARDING_NOT_PASSED),
		]),
		z.boolean(),
	)
	.transform(result => {
		let updatedResult: { [key in Notifications]?: NotificationStatus } = {}
		for (const notification in result) {
			updatedResult = {
				...updatedResult,
				[notification]: result[notification as Notifications] ? 'VISIBLE' : 'HIDDEN',
			}
		}
		return updatedResult
	})

export const PreferencesSchema: z.ZodSchema<UserPreferences, z.ZodTypeDef, unknown> = z
	.object({
		plan: PricingPlanSchema,
		uuid: z.string().uuid(),
		lang: z.optional(z.string().length(2)),
		timeFormat24: z.boolean(),
		country: z.optional(z.string().length(2)),
		timeZone: z.optional(z.string()),
		notifications: NotificationsSchema,
		nextSubscriptionPeriod: z.string(),
		more: z.object({
			postsLimit: z.number(),
			channelsLimit: z.number(),
			membersLimit: z.number(),
			publishedPostsCount: z.number().min(0),
			notifyOnPublish: z.boolean(),
			simpleFeedView: z.boolean(),
			supportedOnlyFeedView: z.boolean(),
			silentBotNotifications: z.boolean(),
			sidebarIsOpen: z.boolean(),
			feedIsOpen: z.boolean(),
			disableTracking: z.boolean(),
		}),
	})
	.transform(response => ({
		...response,
		limits: {
			posts: response.more.postsLimit,
			channels: response.more.channelsLimit,
			members: response.more.membersLimit,
			monthlyPublishedPosts: response.more.publishedPostsCount,
		},
		more: {
			sidebarIsOpen: response.more.sidebarIsOpen,
			feedIsOpen: response.more.feedIsOpen,
			disableTracking: response.more.disableTracking,
		},
		feed: {
			isNotifyOnPublishEnabled: response.more.notifyOnPublish,
			isPostImagesShown: !response.more.simpleFeedView,
			isUnsupportedPostsShown: !response.more.supportedOnlyFeedView,
			isBotNotificationSilent: response.more.silentBotNotifications,
		},
	}))
