import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { VStack } from '@prostpost/uikit'
import { Typography } from '@prostpost/uikit-next'

import type { PostHistoryStickerMeta } from 'app/domains/Post'

type Props = {
	meta: PostHistoryStickerMeta
}

export const Sticker = (_props: Props) => {
	const theme = useTheme()
	const { t } = useTranslation()
	return (
		<VStack align="center" just="center" bg="blue_10" borderRadius="small" p={4} mt={3} space={2}>
			<Icons.Sticker size={24} strokeWidth={2} color={theme.colors.blue_40} />
			<Typography variant="text" color="gray_200" size={13}>
				{t('content:feed.sticker.label', 'Sticker message')}
			</Typography>
		</VStack>
	)
}
